import React, { useState, useEffect, useRef } from "react"
import _ from "lodash"
import styled from "styled-components"
import { List } from "antd"
import ScrollContainer from "react-indiana-drag-scroll"

import { Color } from "../config"
import Layout from "../components/layout"
import { useColorsContext, useColorsDataLoading } from "../data/colorsContext"
import { ProjectItem } from "../models/models"

const ProjectsPage = () => {
  const { ctxProjects } = useColorsContext()

  const [selectedProject, setselectedProject] = useState({} as ProjectItem)

  const container = useRef(null)
  function projectClicked(item: ProjectItem) {
    //container.current.scrollTo(0, 0)
    setselectedProject(item)
  }

  useColorsDataLoading()

  useEffect(() => {
    if (_.isEmpty(selectedProject) && ctxProjects !== undefined) {
      //set default start project
      setselectedProject(ctxProjects[0])
    }
    if (container.current) {
      container.current.scrollTo(0, 0)
    }
  }, [ctxProjects])

  useEffect(() => {
    if (container.current) {
      container.current.scrollTo(0, 0)
    }
  }, [selectedProject])

  return (
    <Layout
      title={"Projects"}
      description={"projects"}
      backgroundColor={Color.BLACK}
    >
      <StyledHeading>Projects</StyledHeading>
      {ctxProjects !== undefined && selectedProject !== undefined ? (
        ctxProjects.length > 0 ? (
          <ProjectsContainer>
            <ProjectsGalleryContainer>
              <StyledScrollContainer hideScrollbars={false}>
                <StyledSection className="section" ref={container}>
                  {selectedProject !== undefined &&
                  selectedProject.Gallery !== undefined ? (
                    selectedProject.Gallery.length > 0 ? (
                      selectedProject.Gallery.map((x, index) => {
                        return <StyledImg src={x.path} key={index} />
                      })
                    ) : (
                      <div>Gallery empty...</div>
                    )
                  ) : (
                    <div>Gallery empty...</div>
                  )}
                </StyledSection>
              </StyledScrollContainer>
            </ProjectsGalleryContainer>
            <ProjectsMenuContainer
              dataSource={ctxProjects}
              // tslint:disable-next-line:jsx-no-lambda
              renderItem={(item) => (
                <ProjectLine
                  className={
                    selectedProject !== undefined
                      ? selectedProject.Name == item.Name
                        ? "active"
                        : ""
                      : undefined
                  }
                  onClick={() => {
                    projectClicked(item as ProjectItem)
                    //setselectedProject(item as ProjectItem)
                  }}
                >
                  <ProjectLineText className="title">
                    {item.Name || ""}
                  </ProjectLineText>
                  {selectedProject !== undefined &&
                  selectedProject.Name == item.Name ? (
                    <ProjectLineDetails>
                      <ProjectLineDetailsTitle>Client</ProjectLineDetailsTitle>
                      {selectedProject.Client}
                      <br />
                      <ProjectLineDetailsTitle>Date</ProjectLineDetailsTitle>
                      {selectedProject.Date}
                      <br />
                      <ProjectLineDetailsTitle>
                        Category
                      </ProjectLineDetailsTitle>
                      {selectedProject.Category}
                      <br />
                      <ProjectLineDetailsTitle>Ref</ProjectLineDetailsTitle>
                      {selectedProject.Ref}
                    </ProjectLineDetails>
                  ) : (
                    ""
                  )}
                </ProjectLine>
              )}
            />
          </ProjectsContainer>
        ) : null
      ) : null}
    </Layout>
  )
}

/*
display: "-webkit-box",
                              display: "-ms-flexbox",
                              display: "-webkit-flex",
                              display: "flex",
                             
                              justify-content: center,
                              align-items: center */
export default ProjectsPage

const ProjectsContainer = styled.div`
  height: 100% !important;
`
const ProjectsMenuContainerOLD = styled(List)`
  float: left;
  margin-left: 25px !important;
  position: fixed !important;
  top: 30%;
  max-height: 296px;
  overflow-y: scroll;
  width: 290px;
  @media (max-width: 768px) {
    z-index: 9;
    max-height: 140px;
    top: unset;
    position: static !important;
    bottom: calc(180px);
    margin-left: auto !important;
    margin-right: auto !important;
    float: unset !important;
    margin-top: calc(100% - 80px);
  }
`
const ProjectsMenuContainer = styled(List)`
  float: left;
  margin-left: 25px !important;
  position: fixed !important;
  top: 30%;
  max-height: 296px;
  overflow-y: scroll;
  width: 290px;
  @media (max-width: 768px) {
    z-index: 0;
    top: unset;
    position: relative !important;
    bottom: 0;
    height: 25% !important;
    margin: 10px auto !important;

    float: unset !important;
  }
`
const ProjectsGalleryContainer = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  width: auto;
  left: 325px;
  height: calc(100% - 80px);
  background-color: #fff;
  @media (max-width: 768px) {
    position: relative !important;
    z-index: 0;
    left: 0;
    width: 100%;
    height: 70%;
  }
`

const ProjectLine = styled(List.Item)`
  cursor: pointer;
  margin-left: 1px;
  padding: 6px 0 !important;
  color: ${Color.WHITE} !important;
  font-size: 12px;
  text-transform: uppercase;
  margin-right: 20px;
  display: list-item;
  :hover span {
    text-decoration: line-through;
  }
`
const ProjectLineText = styled.span`
  display: block;
  word-wrap: break-word;
  font-weight: bold;
  font-size: 14px;
`
const ProjectLineDetails = styled.div`
  word-wrap: break-word;
  display: block !important;

  text-transform: uppercase;
  text-decoration: none !important;
  padding: 15px !important;
  font-weight: bold;
`
const ProjectLineDetailsTitle = styled.span`
  text-decoration: none !important;
  font-size: 10px;
  font-weight: normal;
  margin-right: 10px;
`
const StyledHeading = styled.h1`
  color: #000;
  z-index: -999999999;
  position: absolute;
  right: 0;
`

const ProjectsGalleryContainerOLD = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  width: 75%;
  height: calc(100% - 80px);
  background-color: #fff;
  @media (max-width: 768px) {
    z-index: -9;
    width: 100%;
    height: 60%;
  }
`

const StyledScrollContainer = styled(ScrollContainer)`
  height: 100%;
  width: 100%;
  overflow: hidden !important;
  overflow-x: scroll !important;

  ::-webkit-scrollbar-track {
    /*background-color: #0b4f28;*/
    background-color: #002855;
  }

  ::-webkit-scrollbar {
    width: 10px;
    /*background-color: #0b4f28;*/
    background-color: #002855;
  }

  ::-webkit-scrollbar-thumb {
    /*background-color: #0a9848;*/
    background-color: #2d00f7;
  }
`

const StyledSection = styled.section`
  padding: 0px;
  margin: 0px;
  height: 100%;
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const StyledImg = styled.img`
  height: 100%;
  display: flex;
  width: auto;
  align-items: center;
  justify-content: center;
  cursor: grab;
  flex: 1;
`
